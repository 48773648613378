import React from 'react';
import { useCallback } from 'react';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import { PARTICLES_OPTIONS } from '../../constants/particles';

const Background = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return <Particles init={particlesInit} options={PARTICLES_OPTIONS} />;
};

export default Background;
