import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const Icon = ({ component, title = '', ...props }) => {
  return (
    <Tooltip title={title}>
      <a target="_blank" {...props}>
        <img src={component} alt={title} />
      </a>
    </Tooltip>
  );
};

export default Icon;
