export const STRINGS = {
  EMAIL: 'hi@edgargrs.com',
  EDGARGRS: 'edgargrs',
  MY_NAME_IS: 'My name is Edgar 🖖',
  REACT_NATIVE_DEVELOPER: 'React Native Developer',
};

export const GREETINGS = [
  'Hello', // English
  'Hola', // Spanish
  '你好', // Chinese (Mandarin)
  'Здравствуйте', // Russian
  'नमस्ते', // Hindi
  'Oi', // Portuguese
  '안녕하세요', // Korean
  'こんにちは', // Japanese
  'Bonjour', // French
  'Привіт', // Ukrainian
];
