import * as React from 'react';
import Welcome from './pages/welcome';
import SEO from './components/seo';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';

const helmetContext = {};

export default function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <div className="App">
        <SEO />
        <Welcome />
      </div>
    </HelmetProvider>
  );
}
