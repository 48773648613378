import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Typewriter from 'typewriter-effect';

import Background from '../../components/background';
import Icon from '../../components/icon';

import images from '../../assets/images/index';
import GithubIcon from '../../assets/svgs/github.svg';
import GitlabIcon from '../../assets/svgs/gitlab.svg';
import LinkedInIcon from '../../assets/svgs/linkedin.svg';
import MailIcon from '../../assets/svgs/mail.svg';

import { GREETINGS, STRINGS } from '../../constants/strings';

const { EDGARGRS, EMAIL, MY_NAME_IS, REACT_NATIVE_DEVELOPER } = STRINGS;

const icons = [
  {
    title: EDGARGRS,
    component: LinkedInIcon,
    href: 'https://www.linkedin.com/in/edgargrs/',
  },
  {
    title: EDGARGRS,
    component: GitlabIcon,
    href: 'https://gitlab.com/edgargrs',
  },
  {
    title: EDGARGRS,
    component: GithubIcon,
    href: 'https://github.com/edgargrs',
  },
  {
    title: EMAIL,
    component: MailIcon,
    href: 'mailto:hi@edgargrs.com',
  },
];

const Welcome = () => {
  const iconList = useCallback(
    () =>
      icons.map((props, i) => (
        <Grid item key={`${props.title}${i}`} xs={3} sm={1} md={1} lg={1}>
          <Icon {...props} />
        </Grid>
      )),
    [icons]
  );

  return (
    <>
      <Background />
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          height: '100vh',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Avatar alt="Profile" src={images.profile} sx={{ width: 100, height: 100 }} />
        <Box mt={5} mb={5}>
          <Typewriter
            options={{
              loop: true,
              autoStart: true,
              strings: GREETINGS,
            }}
          />
          <Typography variant="h4" color="white">
            {MY_NAME_IS}
          </Typography>
          <Typography variant="h6" color="white">
            {REACT_NATIVE_DEVELOPER}
          </Typography>
        </Box>
        <Grid
          mt={2}
          container
          justifyContent="center"
          alignSelf="flex-end"
          spacing={0.5}
          maxWidth={'100%'}
        >
          {iconList()}
        </Grid>
      </Box>
    </>
  );
};

export default Welcome;
